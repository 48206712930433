<template>
  <div>
    <!-- 联系核心按钮开始 -->
    <div class="container">
      <h2 class="text-responsive pt-4 text-white">桂运宝网络货运平台</h2>
      <h3 class="text-white mb-4">省时、省人、省事</h3>

      <div class="d-grid gap-2 d-md-block">
        <!-- <a class="btn btn-darkblue btn-lg ml-4 mt-4" role="button" data-toggle="collapse"
                        href="#contactCollapse" aria-controls="contactCollapse" aria-expanded="false">联系我们</a> -->
        <a
          class="btn btn-success shadow mx-4 my-4 btn-lg text-white"
          type="button"
          href="https://next.guiyunbao.cn"
        >
          <font-awesome-icon class="me-3" icon="store" />
          货主入口
        </a>
        <a
          class="btn btn-success shadow mx-4 my-4 btn-lg text-white"
          type="button"
          href="https://next.guiyunbao.cn"
        >
          <font-awesome-icon class="me-3" icon="id-card" />司机入口
        </a>
        <a
          class="btn btn-success shadow mx-4 my-4 btn-lg text-white"
          href="https://next.guiyunbao.cn/app-release.apk"
          type="button"
          >
          <font-awesome-icon class="me-3" icon="mobile"/>安卓下载
        </a>
        <!-- <router-link
          class="btn btn-success shadow mx-4 my-4 btn-lg text-white"
          to="/roadmap"
          type="button"
          ><font-awesome-icon class="me-3" icon="globe-asia" />
          专线展示</router-link
        > -->
        <!-- 出于浏览速度原因，暂时取消 -->

        <a
          class="btn btn-success shadow mx-4 btn-lg my-4 text-white"
          role="button"
          data-bs-toggle="modal"
          data-bs-target="#feeCheck"
          aria-controls=""
          aria-expanded="false"
          ><font-awesome-icon class="me-3" icon="user-check" /> 运费查询</a
        >
      </div>
    </div>
    <!-- 核心按钮结束 -->
    <!-- 运费查询模块开始 -->
    <div
      class="modal fade"
      id="feeCheck"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-2"
      aria-labelledby="feeCheck"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="feeCheck">运费查询</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <h6>补充说明：</h6>
              <p class="text-warning">
                运费查询仅针对整车业务，且始发地与目的地数据仅限于市级
              </p>
              <div class="form-group">
                <label>始发地</label>
                <span class="text-danger mx-4" v-if="error1"
                  >请填入正确的城市名（暂不支持县级查询）</span
                >
                <input
                  type="text"
                  name=""
                  id=""
                  class="form-control"
                  v-model="locationA"
                />
              </div>
              <div class="form-group">
                <label>目的地</label>
                <span class="text-danger mx-4" v-if="error2"
                  >请填入正确的城市名（暂不支持县级查询）</span
                >
                <input
                  type="text"
                  name=""
                  id=""
                  class="form-control"
                  v-model="locationB"
                />
              </div>
              <div class="form-group">
                <label>吨数</label>
                <span class="text-danger mx-4" v-if="error3"
                  >请填入正确的吨数</span
                >
                <input
                  type="text"
                  name=""
                  id=""
                  class="form-control"
                  v-model="quality"
                />
              </div>
              <div>
                <h3
                  class="alert alert-success"
                  v-if="feeResult != ''"
                  v-html="feeResult"
                ></h3>
              </div>
              <a class="btn btn-primary btn-lg ml-4" v-on:click="CheckFee"
                >开始查询</a
              >
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              关闭
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 运费查询模块结束 -->
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faIdCard,
  faUserCheck,
  faStore,
  faGlobeAsia,
} from "@fortawesome/free-solid-svg-icons";
// import {} from "@fortawesome/free-brands-svg-icons";
// import {} from "@fortawesome/free-regular-svg-icons";
library.add(faIdCard, faUserCheck, faStore, faGlobeAsia);
// @ is an alias to /src
import axios from "axios";
export default {
  name: "FeeCheck",
  data: function () {
    return {
      locationA: "",
      locationB: "",
      quality: 0,
      price: 0,
      feeResult: "",
      error1: false,
      error2: false,
      error3: false,
    };
  },

  methods: {
    CheckFee: function () {
      // 确定表格是否填写完毕
      if (this.locationA === "") {
        this.error1 = true;
      } else {
        this.error1 = false;
      }
      if (this.locationB === "") {
        this.error2 = true;
      } else {
        this.error2 = false;
      }
      if (this.quality == 0 || isNaN(this.quality) || this.quality == "") {
        this.error3 = true;
      } else {
        this.error3 = false;
      }
      // 表格填写完毕之后
      if (!this.error1 && !this.error2 && !this.error3) {
        if (this.locationA === this.locationB) {
          this.feeResult = "若是同城转运，请联系客服热线：0772-2991568。";
        } else {
          axios
            .get(
              this.$backend_url +
                "cartage/" +
                this.locationA +
                "/" +
                this.locationB
            )
            .then((res) => {
              this.price = res.data;
              var fee = this.quality * this.price;
              this.feeResult =
                "当前两地货运单价为：" +
                this.price +
                "元/吨。<br>" +
                "您的运费大概是：" +
                fee +
                "元。";
            })
            .catch(() => {
              this.feeResult =
                "抱歉，您的市县数据暂未录入系统或市县数据不正确，运费详情请联系客服热线：0772-2991568。";
            });
        }
      }
    },
  },
};
</script>
 <style scoped>
</style>