import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './styles/main.scss'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import * as echarts from 'echarts/core'
// 出于浏览速度原因，暂时取消
// import china from '@/assets/json/china.json'
// 出于浏览速度原因，暂时取消

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'



Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.config.productionTip = false
Vue.use(VueAxios, axios)
// 后台链接，发布时要更改，同时要设置Access-Control-Allow-Origin
Vue.prototype.$backend_url = 'https://168.guiyunbao.cn/'
//Vue.prototype.$backend_url = ''

// Vue.prototype.$echarts = echarts
// Vue.prototype.echartsResize = function (ref) {
//   setInterval(() => {
//     window.onresize = function () {
//       ref.resize()
//     }, 100
//   })
// }
// 出于浏览速度原因，暂时取消
// echarts.registerMap('china', china)
// 出于浏览速度原因，暂时取消
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
