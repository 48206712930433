<template>
  <div class="container-fluid mx-0 px-0">
    <div class="container-fluid px-0 mx-0 bg-darkblue d-block d-xl-none w-100">
      <a
        @click="showCollapse = !showCollapse"
        class="container-fluid mx-0 px-0"
      >
        <div class="text-on-img text-center container-fluid mx-0 px-0">
          <img
            src="../assets/images/bg_s-100.jpg"
            alt=""
            class="img_cannot_download img-fluid mx-0 w-100"
          />
          <div class="caption text-white">
            <p>点击切换详细/缩略模式</p>
            <h4>定制广西自卸大宗货物运输解决方案</h4>
            <h4>业务辐射华南、华东、西南地区专线运输</h4>
          </div>
        </div>
      </a>
      <div>
        <div class="mycontainer overflow-auto">
          <!-- 轮播小屏幕开始 -->
          <my-carousel
            v-show="showCollapse"
            myID="mdCarousel"
            class="mycarousel-md"
          />
          <!-- <road-map
            class="container-fluid mx-0 roadmap-background"
            v-show="showCollapse"
          /> -->
        </div>
      </div>
    </div>
    <div class="container-fluid bg-mypics d-none d-xl-block">
      <!-- <mytestcomponents msg="" /> -->
      <!-- 轮播大屏幕 -->
      <my-carousel myID="xlCarousel" class="mycarousel-xl" />
      <h2 class="text-white ms-4 my-4">定制广西自卸大宗货物运输解决方案</h2>
      <h2 class="text-white ms-4">业务辐射华南、华东、西南地区专线运输</h2>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import myCarousel from "@/components/myCarousel.vue";

export default {
  name: "Home",
  components: {
    myCarousel,
  },
  data: function () {
    return {
      showCollapse: true,
    };
  },
};
</script>
 <style>
.text-on-img {
  position: relative;
}

.caption {
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
}
.bg-darkblue {
  background-color: #023047;
}
.bg-mypics {
  background-image: linear-gradient(
      to bottom,
      rgba(33, 158, 188, 0) 90%,
      rgba(0, 147, 188, 1) 99%
    ),
    url(../assets/images/bg-100v2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
.mycarousel-xl {
  width: 38vw;
}
.mycarousel-md {
  width: 100vw;
}
</style>
